import {graphql, Link} from 'gatsby'
import React from 'react'
import Layout from '../components/layout/index'
import SEO from '../components/seo'
import {rhythm } from '../utils/typography'

const readingListPageSyle = {
  headerStyle: {textAlign: 'center'},
  titleStyle: {
    marginTop: rhythm(1),
    marginBottom: 0,
    fontFamily: `Dosis`,
    fontWeight: 200,
  },
}


const ReadingListPage = ({data, location}) => {
  const {headerStyle, titleStyle} = readingListPageSyle
  const title = 'My reading list'
  return (
    <Layout location={location}>
      <SEO
        title={title}
        description={title}
      />
      <article>
        <header style={headerStyle}>
          <h1 style={titleStyle}>
            <span role='img' aria-label='books'>
              📚
            </span>
            {title}        
          </h1>
        </header>
        <section>
        <br/>
        <p>Here is a list of books I have read and I recommend:</p>
        <ul>
          <li>
            <a href="https://www.amazon.co.uk/dp/0262033844/ref=cm_sw_em_r_mt_dp_U_uIMWEbJ653X2H" target="_blank" rel="noopener noreferrer">Introduction to Algorithms</a>
            <br/>
            <p>
              It is hard to express how much I can recommend this book. My first contact with this masterpiece was in the high school, 
              while I was trying to study algorithms for competitive programming. I could obtain some knowledge, but I was not 'mathematically literate' to appreciate
              the rigour and algorithm analysis discussions.
              Couple of years later, it was the university algorithms course textbook, and I could truly understand the material explained here.
              <br/>
              For me, this is possibly the 'the best' of algorithms and data structures resources available.
              I think the best part is algorithm analysis, not the actual pseudocode which is sometimes hard to read.
              Analysis is usually very formal and many proofs regarding the complexity and correctness are extremely clear and usually very rigorous.
              I consider the pseudocode the only drawback and the fact the book uses 1-based array indexing (CLRS, why this?).
              <br/>
              The authors clearly explain the need for certain algorithms and data-structures and discuss both theoretical and practical aspects.
              <br/>
              Almost all sections have the accompanying exercises, thoroughly testing understanding. Some of them can be very interesting, usually end of chapter problems are hard.
              For example, after Red Black Trees chapter, AVL trees appear as an exercise to the reader {'<3'}.
              <br/>
              My favourite parts are Heaps, Hash-Tables, the whole Graph Algorithms section and Dynamic Programming section.
              Personally, I found it very difficult to grasp dynamic programming concepts, but this textbook really explains it properly. There are plenty of worked-out examples, each thoroughly discussed with proofs regarding correctness, running time and even the practical implementation advice.
              <br/>
              To sum up, there is also the accompanying online course taught at MIT. <a href="https://ocw.mit.edu/courses/electrical-engineering-and-computer-science/6-006-introduction-to-algorithms-fall-2011/" target="_blank"  rel="noopener noreferrer">Learn more here.</a>
              <br/>
              Although it is not an easy read, I think it is one of 'must reads'.
            </p>

          </li>
        </ul>
        <p>Here is a list of books that are 'in priority queue':</p>
        <ul>
          <li>
            <a href="https://www.amazon.co.uk/dp/012088478X/ref=cm_sw_em_r_mt_dp_U_NIMWEbHS6G7N5" target="_blank" rel="noopener noreferrer">Engineering a Compiler</a>
          </li>
          <li>
            <a href=" https://www.amazon.co.uk/dp/0387797106/ref=cm_sw_em_r_mt_dp_U_cIMWEb0T50XDX" target="_blank" rel="noopener noreferrer">Combinatorics and Graph Theory</a>
          </li>
          <li>
            <a href="https://www.amazon.co.uk/dp/3319110799/ref=cm_sw_em_r_mt_dp_U_KHMWEbBZ43H7X" target="_blank" rel="noopener noreferrer">Linear Algebra Done Right</a>
          </li>
          <li>
            <a href="https://www.amazon.co.uk/dp/1133187811/ref=cm_sw_em_r_mt_dp_U_PJMWEb3FK2AS7" target="_blank" rel="noopener noreferrer">Introduction to the Theory of Computation</a>
          </li>
          <li>
            <a href="https://www.amazon.co.uk/dp/0262510871/ref=cm_sw_em_r_mt_dp_U_GKMWEbKYH8SBN" target="_blank" rel="noopener noreferrer">Structure and Interpretation of Computer Programs</a>
          </li>
          <li>
            <a href="https://www.amazon.co.uk/dp/0521663504/ref=cm_sw_em_r_mt_dp_U_GLMWEbG5E491Q" target="_blank" rel="noopener noreferrer">Purely Functional Data Structures</a>
          </li>
        </ul>
        </section>
      
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>      
            <Link to={'/'} rel='prev'>
              ← {'Home'}
            </Link>
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default ReadingListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
